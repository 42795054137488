<template>
    <div class="content-div">
        <Form :model="formData" :label-colon="true" :label-width="120">
            <FormItem>
                <span slot="label" class="validate">标题:</span>
                <Input v-model.trim="formData.title" :maxlength="100"></Input>
            </FormItem>
            <FormItem class="item-content">
                <span slot="label" class="validate">正文:</span>
                <div
                    ref="input"
                    :name="attr"
                    contenteditable="true"
                    tabindex="1"
                    class="input-text ios-user-select input-text-placeholder"
                    v-html="msg"
                    data-placeholder="请输入正文"
                    @input="edit"
                    id="input-div"
                    v-if="showEdit"
                ></div>
                <!-- <div id="smsDetail" class="edit-content" ref="editContent" contenteditable="true" @input="handleMsg" @focus="handleMsg" @blur="handleMsg" v-html="msg"></div> -->
                <Button class="btn" type="primary" @click="insertNoteValue">插入可填写部分</Button>
                <Button class="btn other" type="primary" @click="officialStatusFn(true)">插入图片</Button>
                <div class="desc-view">
                    <p>操作说明:</p>
                    <p>1、请将文件的标题、正文、签署区名称填上对应的内容;</p>
                    <p>2、确定检查好内容无误;</p>
                    <p>3、将需要填写的地方[插入可填写部分];</p>
                    <p>4、点击确定。</p>
                </div>
            </FormItem>
            <FormItem>
                <span slot="label" class="validate">签署区名称:</span>
                <Input v-model.trim="formData.name" :maxlength="10" placeholder="如申请人"></Input>
            </FormItem>
            <FormItem class="item-content">
                <span slot="label">补充说明</span>
                <Input type="textarea" v-model="formData.remark"></Input>
                <div class="desc-view-content">
                    <p>说明:</p>
                    <p>居民填写时不显示该内容，会显示在打印或已处理的文件末</p>
                </div>
            </FormItem>
            <FormItem class="item-content">
                <span slot="label">上传电子印章</span>
                <Button type="primary" v-if="formData && formData.id !='' && !showImg" @click="deleteImg">已上传，点击重新上传</Button>
                <LiefengUpload ref="imgData" v-if="showImageUpload && showImg" accept=".png" :format="['png']" :defaultList="imgData" :showView="true"></LiefengUpload>
                <div class="desc-view-content none">
                    <p>说明：格式为png透明底图片，不留边</p>
                    <p>上传电子印章后，系统会根据处理确定状态给居民返回盖章文件</p>
                </div>
            </FormItem>
        </Form>

        <LiefengModal title="上传图片" :value="officialStatus" @input="officialStatusFn" :fullscreen="false">
            <template v-slot:contentarea>
                <LiefengUpload ref="imgData2" v-if="showInsertImg" accept=".jpg,.png,.gif,.jpeg" :format="['jpg', 'png', 'jpeg', 'gif']" :showView="true"></LiefengUpload>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" @click="officialStatusFn(false)">取消</Button>
                <Button style="margin-left: 10px" type="primary" @click="saveImage">确定</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengUpload from "@/components/LiefengUpload"
import LiefengModal from "@/components/LiefengModal"
export default {
    components: { LiefengUpload,LiefengModal },
    props: {
        formData: Object,
    },
    data() {
        return {
            event: {},
            SmsDetails: "",
            msg: "",
            attr: "content",
            contentValue: "",
            imgData: [],
            showImageUpload: false,
            showImg:true,
            officialStatus:false,
            showInsertImg:true,

            startOffset:0,
            endOffset:0,

            showEdit:true,

            startContainer:null
        }
    },
    beforeDestory() {
        // 清除监听中文输入
        const el = this.$refs.input
        el.removeEventListener("compositionstart", this.onCompositionstart, false)
        el.removeEventListener("compositionend", this.onCompositionend, false)
    },
    mounted() {
        // 监听中文输入
        const el = this.$refs.input
        el.addEventListener("compositionstart", this.onCompositionstart, false)
        el.addEventListener("compositionend", this.onCompositionend, false)
    },
    methods: {
        officialStatusFn(status){
            this.showInsertImg = false
            if(status){
                this.$nextTick(()=>{
                     this.getCursor()
                     this.showInsertImg = true
                })
            }
            this.$nextTick(()=>{
                this.showInsertImg = true
            })
            this.officialStatus = status
        },
        saveImage(){
            if(this.$refs.imgData2 && this.$refs.imgData2.uploadList && this.$refs.imgData2.uploadList.length){
                document.getElementById("input-div").focus()
                this.insertHtmlAtCaret(`<img id='insert-view' src=${this.$refs.imgData2.uploadList[0].url} ></img>`,true)
                this.officialStatusFn(false)
            }else{  
                this.$Message.warning({
                    content:'请上传图片',
                    background:true
                })
            }
        },
        deleteImg(){
            this.imgData = []
            this.showImageUpload = false
            this.showImg = true
            this.$nextTick(()=>{
                this.showImageUpload = true
            })
        },
        clearInput() {
            this.msg = ""
            let dataJson = document.getElementById("input-div")
            dataJson.innerHTML = ""
        },
        onCompositionstart(e) {
            this.isLock = true
        },
        onCompositionend(e) {
            this.isLock = false
        },
        edit(e) {
            this.event = e
            // 解决中文输入的时候，直接输出英文字母的问题(中文输入期间，不允许输入字符)
            setTimeout(() => {
                if (this.isLock) return
                let value = e.target.innerHTML
                // 去除换行符

                value = value.replace(/(\<\/?\w*\>)/g, "").replace(/(\r\n)|(\n)/g, "")
                this.contentValue = value
            }, 0)
            // 如果是pc的话，timeout设置为5就好，ios经测试，使用40才有效果
            const timeout = 40
            setTimeout(() => {
                this.getCursor(e.target)
            }, timeout)
        },
        // 解决光标定位问题
        getCursor() {
                var selection = window.getSelection()
                var range = selection.getRangeAt(0)
                var textNode = range.startContainer
                let range2 = document.createRange()
                range2.setStart(textNode, range.startOffset < 1 ? 0 : range.startOffset - 1)
                range2.setEnd(textNode, range.endOffset)
                range.collapse(true)
                this.startContainer = textNode
                this.startOffset = range.startOffset
                this.endOffset = range.endOffset
        },
        async insertNoteValue() {
            document.getElementById("input-div").focus()
            this.insertHtmlAtCaret("<img id='input-view'></img>")
        },
        insertHtmlAtCaret(html,status) {
            var sel, range
            if (window.getSelection) {
                // IE9 and non-IE
                sel = window.getSelection()
                if (sel.getRangeAt && sel.rangeCount) {
                    range = sel.getRangeAt(0)
                    range.deleteContents()
                    var el = document.createElement("div")
                    el.innerHTML = html
                    var frag = document.createDocumentFragment(),
                        node,
                        lastNode
                    while ((node = el.firstChild)) {
                        lastNode = frag.appendChild(node)
                    }
                    if(status){
                        range.setStart(this.startContainer, this.startOffset)
                        range.setEnd(this.startContainer, this.endOffset)
                        range.insertNode(frag)
                    }else{
                        range.insertNode(frag)
                    }
                    // Preserve the selection
                    if (lastNode) {
                        range = range.cloneRange()
                        range.setStartAfter(lastNode)
                        range.collapse(true)
                        sel.removeAllRanges()
                        sel.addRange(range)
                    }
                    
                }
            } else if (document.selection && document.selection.type != "Control") {
                document.selection.createRange().pasteHTML(html)
            }
        },
        // 点击保存
        async save() {
            let dataJson = document.getElementById("input-div")
            if (!this.formData.title || this.formData.title == "") {
                this.$Message.warning({
                    content: "请输入标题",
                    background: true,
                })
                return false
            }
            if (!dataJson.innerHTML || dataJson.innerHTML == "") {
                this.$Message.warning({
                    content: "请输入正文",
                    background: true,
                })
                return false
            }
            if (!this.formData.name || this.formData.name == "") {
                this.$Message.warning({
                    content: "请输入签署区名称",
                    background: true,
                })
                return false
            }
            if (this.$refs.imgData && this.$refs.imgData.uploadList && this.$refs.imgData.uploadList.length) {
                this.formData.cachet = this.$refs.imgData.uploadList[0].url
            } else {
                this.formData.cachet = ""
            }
            let dataJsonValue = JSON.stringify({ content: dataJson.innerHTML, name: this.formData.name })
            return await this.$post(
                "/gateway/syinfopublish/api/pc/applyAccord/addApplyAccord",
                {
                    orgCode: "",
                    orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                    dataJson: dataJsonValue,
                    ...this.formData,
                },
                { "Content-Type": "Application/json" }
            ).then(async res => {
                if (res && res.code == 200) {
                    this.$Message.success({
                        content: "新增成功",
                        background: true,
                    })
                    return await new Promise(resolve => {
                        resolve(true)
                    }).then(res => {
                        return res
                    })
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return await new Promise(resolve => {
                        resolve(false)
                    }).then(res => {
                        return res
                    })
                }
            })
        },
        async editSave() {
            let dataJson = document.getElementById("input-div")
            if (!this.formData.title || this.formData.title == "") {
                this.$Message.warning({
                    content: "请输入标题",
                    background: true,
                })
                return false
            }
            if (!dataJson.innerHTML || dataJson.innerHTML == "") {
                this.$Message.warning({
                    content: "请输入正文",
                    background: true,
                })
                return false
            }
            if (!this.formData.name || this.formData.name == "") {
                this.$Message.warning({
                    content: "请输入签署区名称",
                    background: true,
                })
                return false
            }
            let dataJsonValue = JSON.stringify({ content: dataJson.innerHTML, name: this.formData.name })

            if (this.$refs.imgData && this.$refs.imgData.uploadList && this.$refs.imgData.uploadList.length) {
                this.formData.cachet = this.$refs.imgData.uploadList[0].url
            } else {
                if(this.showImg == false && this.formData.cachet != ''){}
                else{this.formData.cachet = ""}
            }

            return await this.$post(
                "/gateway/syinfopublish/api/pc/applyAccord/updateApplyAccord",
                {
                    id: this.formData.id,
                    orgCode: "",
                    orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                    dataJson: dataJsonValue,
                    ...this.formData
                },
                { "Content-Type": "Application/json" }
            ).then(async res => {
                if (res && res.code == 200) {
                    this.$Message.success({
                        content: "修改成功",
                        background: true,
                    })
                    return await new Promise(resolve => {
                        resolve(true)
                    }).then(res => {
                        return res
                    })
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return await new Promise(resolve => {
                        resolve(false)
                    }).then(res => {
                        return res
                    })
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.content-div {
    width: 50%;

    .item-content {
        position: relative;
        .btn {
            position: absolute;
            right: -140px;
            top: 0;
        }
        .other{
            top: 45px;
            right: -100px;
        }
        .desc-view {
            position: absolute;
            right: -360px;
            top: 85px;
            color: #333;
            &-content {
                top: -5px;
                right: -374px;
                position: absolute;
                color: #333;
            }
            
        }
        .none{
                top: 10px;
                left: 180px;
            }
    }
    .input-text {
        border: 1px solid #ccc;
        height: 400px;
        overflow-y: scroll;
        border-radius: 5px;
        padding: 5px;
        width: 100%;
        word-break: break-all;
    }
    /* 当div为空时，显示data-placeholder的值*/
    .input-text-placeholder:empty:before {
        content: attr(data-placeholder);
        color: #cacaca;
    }
    .ios-user-select {
        -webkit-user-select: text;
    }
    /deep/ #input-view {
        // width:100px;
        padding: 10px 20px 0 20px;
        display: inline-block;
        border-bottom: 1px solid #ccc;
    }
    /deep/ #insert-view{
        max-width: 50%;
        margin: 20px auto;
        display: block;
    }
}
</style>